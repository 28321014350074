// we don't have status id
export const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
} as const;

export const MODES = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
} as const;

export const COUNTRIES = {
  INDIA: 'India',
  //add as needed
};

export const INPUT_FIELDS_LENGTHS = {
  FIRST_NAME: {
    MIN: 0,
    MAX: 255,
  },
  LAST_NAME: {
    MIN: 0,
    MAX: 255,
  },
  EMAIL: {
    MIN: 0,
    MAX: 255,
  },
  PHONE_NUMBER: {
    MIN: 0,
    MAX: 16,
  },
  REFERRAL: {
    MIN: 0,
    MAX: 255,
  },
  REMARK: {
    MIN: 0,
    MAX: 255,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 20,
  },
} as const;

export const INPUT_FIELD_REGEX = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
  PHONE_NUMBER: /^\+?\d{1,4}\s?\d{1,14}$/,
} as const;

export const REQUEST_STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export const TRANSCTION_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  CREDIT: 'CREDIT',
};
export const VERIFY_OTP_STATUS = {
  SUCCESS: 1,
};
export const TRANSACTION_TYPES_KEYS = {
  MT5: 'MT5',
  WALLET: 'WALLET',
};

export const OTP_RESEND_INTERVAL_SECONDS = 60;

export const SEARCH_FILTERS = {
  TRANSACTION_TYPE: 'transaction_type',
  SEARCH: 'value',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const TRADING_ACCOUNT_TYPE = {
  LIVE: 'live',
  DEMO: 'demo',
};

export const CHANGE_LEVERAGE_TYPE = {
  LEVERAGE: 'LEVERAGE',
  PASSWORD: 'PASSWORD',
};
export const BANK_STATEMENT_DOCUMENT_DETAIL = {
  document_name: 'Bank Statement',
  id: 11,
};
