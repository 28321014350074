import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const DynamicBreadcrumbs = ({ routes }: { routes: any[] }) => {
  const location = useLocation();
  const pathnames = location.pathname;

  const findRoute = (pathname: string) =>
    routes.find((route) => {
      if (route.path === pathname) return true;

      if (route.path.includes(':')) {
        const routeRegex = new RegExp(
          `^${route.path.replace(/:\w+/g, '\\d+')}$`,
        );
        return routeRegex.test(pathname);
      }
      return false;
    });

  const route = findRoute(pathnames);

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<ChevronRightIcon sx={{ fontSize: '1.2em' }} />}
    >
      <Link to='/'>Dashboard</Link>

      {route?.breadcrumb &&
        Array.isArray(route?.breadcrumb) &&
        route?.breadcrumb.map((item: any, i: number) =>
          route?.breadcrumb?.length - 1 === i ? (
            <Typography key={i}>{item.breadcrumb}</Typography>
          ) : (
            <Link key={i} to={item?.path}>
              {item?.breadcrumb}
            </Link>
          ),
        )}

      {route?.breadcrumb && !Array.isArray(route?.breadcrumb) && (
        <Typography>{route.breadcrumb}</Typography>
      )}
    </Breadcrumbs>
  );
};

export default DynamicBreadcrumbs;
