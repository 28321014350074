import { patch } from 'v2s-ui-core/app/Integrations/ApiService';
import { syncAuthDataInStorage } from '../../../../../Auth/config/utils';
import { API_URLS } from './api/config';
import messages from './messages';
import { TUpdateUser2FAStatusProps } from './types';

export const upateUser2faStatus = async ({
  userId,
  is_2fa,
  auth,
  setAuth,
  showToastNotification,
  formatMessage,
}: TUpdateUser2FAStatusProps) => {
  try {
    const response = await patch<any>(API_URLS.UPATE_USER_2FA_STATUS(userId), {
      is_2fa,
    });
    setAuth((prevState: any) => ({
      ...prevState,
      ...response?.data?.data,
    }));

    syncAuthDataInStorage({
      ...auth,
      ...response?.data?.data,
    });

    showToastNotification(formatMessage(messages.twoFAToast), 'success');

    return response.data;
  } catch (error) {
    showToastNotification(formatMessage(messages.unableToUpdate2FA), 'error');
  }
};
