import CssBaseline from '@mui/material/CssBaseline';
import Box from 'v2s-ui-core/app/components/Box';
import Appbar from './components/Appbar';
import Sidebar from './components/Sidebar';
import { DRAWER_WIDTH } from './components/Sidebar/constants';
import { StyledProtectedLayout } from './styles';
import { useLayout } from './useLayout';
import { SelectUserTypeDialog } from '~/components/SelectUserTypeDialog';
import { useUserAuth } from '../Auth/AuthProvider';

type TLayoutProps = React.PropsWithChildren;

const ProtectedLayout = (props: TLayoutProps) => {
  const { isSidebarOpen, setIsSidebarOpen } = useLayout();

  const { auth } = useUserAuth();
  return (
    <StyledProtectedLayout>
      <CssBaseline />
      <Appbar open={isSidebarOpen} onSidebarToggle={setIsSidebarOpen} />
      <Box className='content'>
        <Sidebar open={isSidebarOpen} />
        <Box
          className='content-body'
          sx={{
            marginLeft: isSidebarOpen ? `${DRAWER_WIDTH}px` : 8,
            // height: '100vh',
            // minHeight: '100vh',
            // maxHeight: '100vh',
          }}
        >
          {props.children}
        </Box>
        {auth?.user_type === null && <SelectUserTypeDialog />}
      </Box>
    </StyledProtectedLayout>
  );
};

export default ProtectedLayout;
