export const QUERY_KEYS = {
  ACCOUNT_TYPE: 'accountType',
  USER_ACCOUNT: 'userAccount',
  GET_BANK_ACCOUNT: 'getBankAccount',
  GET_LOCAL_CURRENCY: 'getLocalCurrency',
  GET_MT5_ACCOUNT: 'getMT5Account',
  GET_PAYMENT_METHODS: 'getPaymentMethods',
  GET_DOC_TYPES_LIST: 'getDocTypesList',
  GET_WALLET_BALANCE: 'getWalletBalance',
  GET_LEVERAGE: 'getLeverage',
  GET_DETAIL_TRADING: 'getDetailTradingAccount',
} as const;
