import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  changePassword: {
    id: 'userMenu.changePassword',
    defaultMessage: 'Change Password',
  },
  logout: {
    id: 'userMenu.logout',
    defaultMessage: 'Logout',
  },
  personalInformation: {
    id: 'userMenu.personalInformation',
    defaultMessage: 'Personal Information',
  },
  jointAccount: {
    id: 'userMenu.jointAccount',
    defaultMessage: 'Joint Account',
  },
  financialInformation: {
    id: 'userMenu.FinancialInformation',
    defaultMessage: 'Financial Information',
  },
  corporateInformation: {
    id: 'userMenu.corporateInformation',
    defaultMessage: 'Corporate Information',
  },
  successProfileUpdate: {
    id: 'userMenu.successProfileUpdate',
    defaultMessage: 'Your profile has been successfully updated',
  },
  successJointAccountUpdate: {
    id: 'userMenu.successJointAccountUpdate',
    defaultMessage: 'Your joint account has been successfully updated',
  },
  successChangePasswordUpdate: {
    id: 'userMenu.successChangePasswordUpdate',
    defaultMessage: 'Your password has been successfully updated',
  },
  oldPassword: {
    id: 'userMenu.oldPassword',
    defaultMessage: 'Enter Old Password',
  },
  newPassword: {
    id: 'userMenu.odPassword',
    defaultMessage: 'Enter New Password',
  },
  retypePassword: {
    id: 'userMenu.retypePassword',
    defaultMessage: 'Confirm New Password',
  },
  changePasswordBtn: {
    id: 'userMenu.changePasswordBtn',
    defaultMessage: 'Submit',
  },
  myProfile: {
    id: 'userMenu.myProfile',
    defaultMessage: 'My Profile',
  },
  dobTitle: {
    id: 'userMenu.dobTitle',
    defaultMessage: 'Date of Birth',
  },
  country: {
    id: 'userMenu.country',
    defaultMessage: 'Country',
  },
  language: {
    id: 'userMenu.language',
    defaultMessage: 'Language',
  },
  dateOfBirthLabel: {
    id: 'userMenu.dateOfBirthLabel',
    defaultMessage: 'Date of birth *',
  },
});
