import BankAccounts from './images/Bank Accounts.svg';
import InternalTransfersIcon from './images/ChangeLeverage.svg';
import ClientsInOutIcon from './images/ClientsInOut.svg';
import CreditIcon from './images/Credit.svg';
import dashBoardIcon from './images/Dashboard.svg';
import DepositsIcon from './images/Deposits.svg';
import IBManagementIcon from './images/IBManagement.svg';
import MT5PlatformLinksIcon from './images/MT5PlatformLinks.svg';
import MT5Icon from './images/Mt5.svg';
import MyKYCIcon from './images/MyKYC.svg';
import PartnershipIcon from './images/Partnership.svg';
import ReportsIcon from './images/Reports.svg';
import RequestsIcon from './images/Requests.svg';
import SupportIcon from './images/Support.svg';
import TradingAccountsIcon from './images/TradingAccounts.svg';
import WalletsIcon from './images/Wallets.svg';
import WithdrawalIcon from './images/Withdrawal.svg';
import ExpandIcon from './images/expandIcon.svg';

export {
  BankAccounts,
  ClientsInOutIcon,
  CreditIcon,
  DepositsIcon,
  ExpandIcon,
  IBManagementIcon,
  InternalTransfersIcon,
  MT5Icon,
  MT5PlatformLinksIcon,
  MyKYCIcon,
  PartnershipIcon,
  ReportsIcon,
  RequestsIcon,
  SupportIcon,
  TradingAccountsIcon,
  WalletsIcon,
  WithdrawalIcon,
  dashBoardIcon,
};
