import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  firstNameRequired: {
    defaultMessage: 'First name is required',
    id: 'schema.firstNameRequired',
  },
  firstNameTooLong: {
    defaultMessage: 'First name must be at most 255 characters',
    id: 'schema.firstNameTooLong',
  },
  lastNameRequired: {
    defaultMessage: 'Last name is required',
    id: 'schema.lastNameRequired',
  },
  lastNameTooLong: {
    defaultMessage: 'Last name must be at most 255 characters',
    id: 'schema.lastNameTooLong',
  },
  emailRequired: {
    defaultMessage: 'Email is required',
    id: 'schema.emailRequired',
  },
  invalidEmail: {
    defaultMessage: 'Enter a valid email address',
    id: 'schema.invalidEmail',
  },
  emailTooLong: {
    defaultMessage: 'Email must be at most 255 characters',
    id: 'schema.emailTooLong',
  },
  noCountrySelected: {
    defaultMessage: 'Please select at least one country',
    id: 'schema.noCountrySelected',
  },
  invalidPhoneNumber: {
    defaultMessage:
      "Phone number must be entered in the format: '+999 9999999999'. Up to 16 digits allowed.",
    id: 'schema.invalidPhoneNumber',
  },
  phoneNumberTooLong: {
    defaultMessage: 'Phone number must be at most 16 digits',
    id: 'schema.phoneNumberTooLong',
  },
  phoneNumberRequired: {
    defaultMessage: 'Phone number is required',
    id: 'schema.phoneNumberRequired',
  },
  referralRequired: {
    defaultMessage: 'Referral is required',
    id: 'schema.referralRequired',
  },
  referralTooLong: {
    defaultMessage: 'Referral must be at most 255 characters',
    id: 'schema.referralTooLong',
  },
  metaCommentRequired: {
    defaultMessage: 'Meta comment is required',
    id: 'schema.metaCommentRequired',
  },
  metaTooLong: {
    defaultMessage: 'Meta comment must be at most 255 characters',
    id: 'schema.metaTooLong',
  },
  addressTooLong: {
    defaultMessage: 'Address must be at most 100 characters .',
    id: 'schema.referralTooLong',
  },
  passwordRequired: {
    defaultMessage: 'Password is required',
    id: 'schema.passwordRequired',
  },
  invalidPassword: {
    defaultMessage:
      'Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, one number and one special character.',
    id: 'schema.invalidPassword',
  },
  tncNotAgreed: {
    defaultMessage: 'Please click to agree the terms & conditions.',
    id: 'schema.tncNotAgreed',
  },
  fieldRequired: {
    defaultMessage: 'This field is required',
    id: 'schema.fieldRequired',
  },
  localCurrencyRequired: {
    defaultMessage: 'Local currency is required',
    id: 'schema.localCurrencyRequired',
  },
  cryptoCurrencyRequired: {
    defaultMessage: 'Crypto currency is required',
    id: 'schema.cryptoCurrencyRequired',
  },
  mt5AccountRequired: {
    defaultMessage: 'MT5 Account is required',
    id: 'schema.mt5AccountRequired',
  },
  paymentMethodRequired: {
    defaultMessage: 'Payment method is required',
    id: 'schema.paymentMethodRequired',
  },
  tradingAmountRequired: {
    defaultMessage: 'Trading amount is required',
    id: 'schema.tradingAmountRequired',
  },
  localAmountRequired: {
    defaultMessage: ' Amount is required',
    id: 'schema.localAmountRequired',
  },
  localAmountMin: {
    defaultMessage: ' Amount must be a positive number',
    id: 'schema.localAmountMin',
  },
  bankAccountRequired: {
    defaultMessage: 'Bank Account is required',
    id: 'schema.bankAccountRequired',
  },
  tradingCurrencyRequired: {
    defaultMessage: 'Trading currency is required',
    id: 'schema.tradingCurrencyRequired',
  },
  noteTooLong: {
    defaultMessage: 'Note must be at most 255 characters',
    id: 'schema.noteTooLong',
  },
  noteRequired: {
    defaultMessage: 'Note is required',
    id: 'schema.noteRequired',
  },
  languageRequired: {
    defaultMessage: 'Language is required',
    id: 'schema.languageRequired',
  },
  addressLine1Required: {
    defaultMessage: 'Address line 1 is required',
    id: 'schema.addressLine1Required',
  },
  addressLine2Required: {
    defaultMessage: 'Address line 2 is required',
    id: 'schema.addressLine2Required',
  },
  cityTownRequired: {
    defaultMessage: 'City/Town is required',
    id: 'schema.cityTownRequired',
  },
  stateProvinceRequired: {
    defaultMessage: 'State/Province is required',
    id: 'schema.stateProvinceRequired',
  },
  zipPostalCodeRequired: {
    defaultMessage: 'Zip/Postal code is required',
    id: 'schema.zipPostalCodeRequired',
  },
  genderRequired: {
    defaultMessage: 'Gender is required',
    id: 'schema.genderRequired',
  },
  nationalityRequired: {
    defaultMessage: 'Nationality is required',
    id: 'schema.nationalityRequired',
  },
  dateOfBirthRequired: {
    defaultMessage: 'Date of birth is required',
    id: 'schema.dateOfBirthRequired',
  },
  preferredLanguageRequired: {
    defaultMessage: 'Preferred language is required',
    id: 'schema.preferredLanguageRequired',
  },
  fullNameRequired: {
    defaultMessage: 'Full name is required',
    id: 'schema.fullNameRequired',
  },
  leverageRequired: {
    defaultMessage: 'Leverage is required',
    id: 'schema.leverageRequired',
  },
  toAccountRequired: {
    id: 'schema.toAccountRequired',
    defaultMessage: 'To account field is required.',
  },
  fromAccountRequired: {
    id: 'schema.fromAccountRequired',
    defaultMessage: 'From account field is required.',
  },
  compDateOfBirthRequired: {
    id: 'schema.compDateOfBirthRequired',
    defaultMessage: 'Company Start Date is required',
  },
  compNameRequired: {
    id: 'schema.compNameRequired',
    defaultMessage: 'Company Name is required',
  },
  companyLicenseRequired: {
    id: 'schema.companyLicenseRequired',
    defaultMessage: 'Company License is required',
  },
  transferTypeRequired: {
    id: 'schema.transferTypeRequired',
    defaultMessage: 'Transfer type is required',
  },
  cryptoAddRequired: {
    defaultMessage: 'Crypto Account address is required',
    id: 'schema.cryptoAddRequired',
  },
  oldPasswordRequired: {
    defaultMessage: 'Old Password is required',
    id: 'schema.passwordRequired',
  },
  invalidOldPassword: {
    defaultMessage:
      'Old Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, one number and one special character.',
    id: 'schema.invalidPassword',
  },
  messageRequired: {
    defaultMessage: ' Message is required.',
    id: 'schema.messageRequired',
  },
  subjectRequired: {
    defaultMessage: ' Subject is required.',
    id: 'schema.subjectRequired',
  },
});
