import { useRef } from 'react';

import {
  attachRequestInterceptor,
  attachResponseInterceptor,
} from 'v2s-ui-core/app/Integrations/ApiService/baseApiInstance';
import { getAuthToken } from '../Auth/config/utils';

const ServiceInterceptor = ({ children }: { children: JSX.Element }) => {
  const serviceInterceptorsAlreadyAttached = useRef(false);

  if (!serviceInterceptorsAlreadyAttached.current) {
    attachRequestInterceptor((config) => {
      const token = getAuthToken();
      if (
        token &&
        token.accessToken !== null &&
        token &&
        token.accessToken !== ''
      ) {
        config.headers['Authorization'] = `Bearer ${token.accessToken}`;
      }
      return config;
    });

    attachResponseInterceptor((response) => response);
    serviceInterceptorsAlreadyAttached.current = true;
  }

  return children;
};

export default ServiceInterceptor;
