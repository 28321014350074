import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../services/queries/useQueryFactory';
import { TDocumentTypeListResponse } from '~/config/types';

const useGetDocTypeList = <TData, TError = Error>(values: {
  enabled: boolean;
  auth?: boolean;
}) => {
  const queryDetails = useQueryFactory<TData, TError>();

  const queryResult = useQuery<TDocumentTypeListResponse, TError>({
    ...queryDetails.getDocTypesList(),
    enabled: values.enabled,
  });

  return queryResult;
};

export default useGetDocTypeList;
