import * as yup from 'yup';
import {
  AxisBankIcon,
  CryptoMethodIcon,
  LocalTransferIcon,
  PeskaMethodIcon,
  WirePaymentIcon,
} from '~/assets';
import { getOldPasswordSchema, getPasswordSchema } from '~/schema';

// we don't have status id
export const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  ACTIVE: 'Active',
} as const;

export const MODES = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
  IS_FIRST_TIME: 'isFirstTime',
} as const;

export const COUNTRIES = {
  INDIA: 'India',
  //add as needed
};

export const INPUT_FIELDS_LENGTHS = {
  FIRST_NAME: {
    MIN: 0,
    MAX: 100,
  },
  LAST_NAME: {
    MIN: 0,
    MAX: 100,
  },
  EMAIL: {
    MIN: 0,
    MAX: 255,
  },
  PHONE_NUMBER: {
    MIN: 0,
    MAX: 16,
  },
  REFERRAL: {
    MIN: 0,
    MAX: 255,
  },
  REMARK: {
    MIN: 0,
    MAX: 255,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 20,
  },
  ADDRESS: {
    MIN: 8,
    MAX: 50,
  },
} as const;

export const INPUT_FIELD_REGEX = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
  PHONE_NUMBER: /^\+?\d{1,4}\s?\d{1,14}$/,
} as const;

export const REQUEST_STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export const TRANSACTION_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  CREDIT: 'CREDIT',
  TRANSFER: 'TRANSFER',
};
export const VERIFY_OTP_STATUS = {
  SUCCESS: 1,
};
export const TRANSACTION_TYPES_KEYS = {
  MT5: 'MT5',
  WALLET: 'WALLET',
};

export const OTP_RESEND_INTERVAL_SECONDS = 60;

export const SEARCH_FILTERS = {
  TRANSACTION_TYPE: 'transaction_type',
  SEARCH: 'value',
  START_DATE: 'created_at',
  END_DATE: 'end_date',
};

export const TRADING_ACCOUNT_TYPE = {
  LIVE: 'live',
  DEMO: 'demo',
};

export const CHANGE_LEVERAGE_TYPE = {
  LEVERAGE: 'LEVERAGE',
  PASSWORD: 'PASSWORD',
};
export const BANK_STATEMENT_DOCUMENT_DETAIL = {
  document_name: 'Bank Statement',
  id: 11,
};

export const SHOW_WALLET_VALUE = {
  WALLET: 'My Wallet',
};
export const PAYMENT_METHOD_KEYS = {
  LOCAL_TRANSFER: 'Local Transfer',
  WIRE_TRANSFER: 'Wire Transfer',
  ASIA_BANKS: 'Asia Banks',
  PESKA: 'Peska',
  CRYPTO: 'Crypto',
};

export const PAYMENT_METHOD_ICONS = {
  [PAYMENT_METHOD_KEYS.ASIA_BANKS]: AxisBankIcon,
  [PAYMENT_METHOD_KEYS.WIRE_TRANSFER]: WirePaymentIcon,
  [PAYMENT_METHOD_KEYS.LOCAL_TRANSFER]: LocalTransferIcon,
  [PAYMENT_METHOD_KEYS.PESKA]: PeskaMethodIcon,
  [PAYMENT_METHOD_KEYS.CRYPTO]: CryptoMethodIcon,
};

export const CHANGE_PASSWORD_FORM_VALUES = {
  new_password: '',
  confirm_password: '',
  old_password: '',
} as const;

export const getChangePasswordSchema = (formatMessage: any) =>
  yup.object().shape({
    old_password: getOldPasswordSchema(formatMessage),
    new_password: getPasswordSchema(formatMessage),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

export const GET_KYC_UPLOAD_MODE = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

export const USER_TYPE = {
  Individual_Trader: 'Individual_Trader',
  Individual_IB: 'Individual_IB',
};
