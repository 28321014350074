import type { RouteProps } from 'react-router-dom';

import { lazy } from 'react';
import APPLICATION_URLS from './appsConfig';

const Support = lazy(() => import('../../../modules/Support'));
const BankAccount = lazy(() => import('../../../modules/bankAccounts'));
const CreditHistory = lazy(
  () => import('../../../modules/Credit/Container/credit'),
);
const MT5PlatformLinks = lazy(() => import('../../../modules/MTPlatformLinks'));

const TransferForm = lazy(() => import('../../../modules/transferForm'));

const MT5AccountWithdrawalForm = lazy(
  () => import('../../../modules/withdrawalForm/container/MT5Account'),
);

const WalletWithdrawalForm = lazy(
  () => import('../../../modules/withdrawalForm/container/Wallet'),
);
const MT5Withdrawal = lazy(
  () => import('../../../modules/WithdrawalHistory/container/MT5Withdrawal'),
);

const WalletWithdrawal = lazy(
  () => import('../../../modules/WithdrawalHistory/container/WalletWithdrawal'),
);
const MyProfile = lazy(() => import('../../../modules/MyProfile'));

const AuthApp = lazy(() => import('~/modules/Auth'));
const MT5Account = lazy(
  () => import('../../../modules/depositForm/container/MT5Account'),
);
const Wallet = lazy(
  () => import('../../../modules/depositForm/container/Wallet'),
);
const WalletHistory = lazy(
  () => import('../../../modules/depositHistory/container/WalletHistory'),
);
const MT5History = lazy(
  () => import('../../../modules/depositHistory/container/MT5History'),
);
const WalletDetailHistory = lazy(
  () => import('../../../modules/WalletDetailHistory'),
);

const TradingAccounts = lazy(() => import('../../../modules/TradingAccounts'));
const MyKyc = lazy(() => import('../../../modules/MyKyc'));

const TransferHistory = lazy(() => import('../../../modules/TransferHistory'));
const Partnership = lazy(() => import('../../../modules/Partnership'));
// GX_INJECT_MODULES_IMPORT

//-------------------------------DEFAULT ROUTES-------------------------------//

const DEFAULT_ROUTES: RouteProps[] = [];

//-------------------------------AUTH ROUTES-------------------------------//

const AUTH_ROUTES: RouteProps[] = [
  {
    path: APPLICATION_URLS.AUTH,
    element: <AuthApp />,
  },
  // GX_INJECT_AUTH_ROUTES
];

//-------------------------------PROTECTED ROUTES-------------------------------//

const PROTECTED_ROUTES: RouteProps[] = [
  {
    path: APPLICATION_URLS.MY_PROFILE,
    element: <MyProfile />,
  },
  {
    path: APPLICATION_URLS.BANK_ACCOUNTS,
    element: <BankAccount />,
  },

  {
    path: APPLICATION_URLS.DEPOSITS_MT5,
    element: <MT5History />,
  },
  {
    path: APPLICATION_URLS.DEPOSITS_WALLET,
    element: <WalletHistory />,
  },

  {
    path: APPLICATION_URLS.ADD_DEPOSITS_WALLET,
    element: <Wallet />,
  },
  {
    path: APPLICATION_URLS.ADD_DEPOSITS_MT5,
    element: <MT5Account />,
  },
  {
    path: APPLICATION_URLS.WITHDRAWAL_WALLET_HISTORY,
    element: <WalletWithdrawal />,
  },
  {
    path: APPLICATION_URLS.WITHDRAWAL_MT5_HISTORY,
    element: <MT5Withdrawal />,
  },
  {
    path: APPLICATION_URLS.ADD_WITHDRAWAL_WALLET,
    element: <WalletWithdrawalForm />,
  },
  {
    path: APPLICATION_URLS.ADD_WITHDRAWAL_MT5,
    element: <MT5AccountWithdrawalForm />,
  },
  {
    path: APPLICATION_URLS.WALLET_DETAIL_HISTORY,
    element: <WalletDetailHistory />,
  },
  {
    path: APPLICATION_URLS.TRADING_ACCOUNTS,
    element: <TradingAccounts />,
  },
  {
    path: APPLICATION_URLS.MY_KYC,
    element: <MyKyc />,
  },

  {
    path: APPLICATION_URLS.TRANSFER_HISTORY,
    element: <TransferHistory />,
  },
  {
    path: APPLICATION_URLS.Add_TRANSFER,
    element: <TransferForm />,
  },
  {
    path: APPLICATION_URLS.CREDIT,
    element: <CreditHistory />,
  },
  {
    path: APPLICATION_URLS.PARTNERSHIP,
    element: <Partnership />,
  },
  {
    path: APPLICATION_URLS.SUPPORT,
    element: <Support />,
  },
  {
    path: APPLICATION_URLS.MT5_PLATFORM_LINKS,
    element: <MT5PlatformLinks />,
  },
];

export { AUTH_ROUTES, DEFAULT_ROUTES, PROTECTED_ROUTES };
