import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'v2s-ui-core/app/Integrations/react-query';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import { MODES } from '../../config/constants';
import useToast from '../../hooks/useToast';
import { useUserAuth } from '../../shell/components/Auth/AuthProvider';
import { syncAuthDataInStorage } from '../../shell/components/Auth/config/utils';
import { API_URLS } from './api/config';
import { TUserTypePayload } from './constants';
import { SubmitUserTypeService } from './helper';

export const useSubmitUserType = () => {
  const [isUserTypeDialogOpen, setIsUserTypeDialogOpen] = React.useState(true);

  const { triggerSuccessToast, triggerErrorToast } = useToast();

  const { auth, setAuth } = useUserAuth();

  const navigate = useNavigate();

  const onSubmitUserType = (value: any) => {
    const userTypePayload = {
      userTypeId: value.id,
      userId: auth.id,
    };

    syncFormDataToServer(userTypePayload);
  };

  const onSubmitSuccess = (response: any) => {
    triggerSuccessToast(response?.message);
    setIsUserTypeDialogOpen(false);
    setAuth((prevState) => ({
      ...prevState,
      user_type: response?.data.user_type,
    }));
    syncAuthDataInStorage({
      ...auth,
      user_type: response?.data.user_type,
    });
    navigate(`${APPLICATION_URLS.MY_PROFILE}?${MODES.IS_FIRST_TIME}=true`);
  };

  const {
    mutate: syncFormDataToServer,
    isPending,
    data,
    status: submitUserTypeStatus,
  } = useMutation({
    mutationKey: [API_URLS.SUBMIT_USER_TYPE],
    mutationFn: (payload: TUserTypePayload) => SubmitUserTypeService(payload),
    onSuccess: onSubmitSuccess,
    onError: (error: any) => triggerErrorToast(error),
  });

  return {
    isUserTypeDialogOpen,
    onSubmitUserType,
    isPending,
    data,
    status: submitUserTypeStatus,
  };
};
