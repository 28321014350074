import { useState } from 'react';
import { MODES } from '../config/constants';

export const useModalToggle = () => {
  const [isBankAccountFormOpen, setIsBankAccountFormOpen] = useState(false);
  const [mode, setMode] = useState('');

  const onViewClick = () => {
    setMode(MODES.VIEW);
    setIsBankAccountFormOpen(true);
  };

  const onEditClick = () => {
    setMode(MODES.EDIT);
    setIsBankAccountFormOpen(true);
  };

  const onAddClick = () => {
    setMode(MODES.ADD);
    setIsBankAccountFormOpen(true);
  };

  const closeModal = () => {
    setIsBankAccountFormOpen(false);
  };

  return {
    isBankAccountFormOpen,
    closeModal,
    onViewClick,
    onAddClick,
    onEditClick,
    mode,
  };
};
