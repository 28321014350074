import { matchPath } from 'react-router-dom';

const APPLICATIONS = {
  LANDING_PAGE: `LANDING_PAGE`,
  DASHBOARD: `DASHBOARD`,
  AUTH: 'AUTH',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  VERIFY_OTP: 'VERIFY_OTP',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  DEPOSITS: 'DEPOSITS',
  DEPOSITS_HISTORY: 'DEPOSITS_HISTORY',
  DEPOSIT_FORM: 'DEPOSIT_FORM',
  WITHDRAWAL: 'WITHDRAWAL',
  MY_KYC: 'MY_KYC',
  TRADING_ACCOUNTS: 'TRADING_ACCOUNTS',
  TRADING_ACCOUNTS_LIST: 'TRADING_ACCOUNTS_LIST',
  DEPOSITS_WALLET: 'DEPOSITS_WALLET',
  ADD_DEPOSITS_WALLET: 'ADD_DEPOSITS_WALLET',
  DEPOSITS_MT5: 'DEPOSITS_MT5',
  ADD_DEPOSITS_MT5: 'ADD_DEPOSITS_MT5',
  TRANSFERS: 'TRANSFERS',
  REPORTS: 'REPORTS',
  CREDIT: 'CREDIT',
  SUPPORT: 'SUPPORT',
  MT5_PLATFORM_LINKS: 'MT5_PLATFORM_LINKS',
  PARTNERSHIP: 'PARTNERSHIP',
  MY_PROFILE: 'MY_PROFILE',
  WITHDRAWAL_MT5_HISTORY: 'WITHDRAWAL_MT5_HISTORY',
  WITHDRAWAL_WALLET_HISTORY: 'WITHDRAWAL_WALLET_HISTORY',
  ADD_WITHDRAWAL_WALLET: 'ADD_WITHDRAWAL_WALLET',
  ADD_WITHDRAWAL_MT5: 'ADD_WITHDRAWAL_MT5',
  WALLET_DETAIL_HISTORY: 'WALLET_DETAIL_HISTORY',
  // GX_INJECT_APPLICATIONS
} as const;

type TApplications = (typeof APPLICATIONS)[keyof typeof APPLICATIONS];

const APPLICATION_URLS: Partial<Record<TApplications, string>> = {
  [APPLICATIONS.LANDING_PAGE]: `/`,
  [APPLICATIONS.DASHBOARD]: '/dashboard',
  [APPLICATIONS.AUTH]: '/*',
  [APPLICATIONS.LOGIN]: '/login',
  [APPLICATIONS.SIGNUP]: '/signup',
  [APPLICATIONS.FORGOT_PASSWORD]: '/forgot-password',
  [APPLICATIONS.VERIFY_OTP]: '/verify-otp',
  [APPLICATIONS.BANK_ACCOUNTS]: '/bank-accounts',
  [APPLICATIONS.DEPOSITS]: '/deposits',
  // [APPLICATIONS.DEPOSITS_WALLET]: '/deposits/wallet',
  [APPLICATIONS.DEPOSITS_WALLET]: '/deposits/wallet/*',
  [APPLICATIONS.ADD_DEPOSITS_WALLET]: '/deposits/wallet/add-deposit',
  [APPLICATIONS.DEPOSITS_MT5]: '/deposits/mt5/*',
  [APPLICATIONS.ADD_DEPOSITS_MT5]: '/deposits/mt5/add-deposit',
  [APPLICATIONS.DEPOSITS_HISTORY]: '/deposits-history',
  [APPLICATIONS.DEPOSIT_FORM]: '/add-deposit',
  [APPLICATIONS.WITHDRAWAL]: '/withdrawal',
  [APPLICATIONS.MY_KYC]: '/my-kyc',
  [APPLICATIONS.TRADING_ACCOUNTS]: '/trading-accounts/*',
  [APPLICATIONS.TRADING_ACCOUNTS_LIST]: '/trading-accounts/list',
  [APPLICATIONS.TRANSFERS]: '/transfers',
  [APPLICATIONS.REPORTS]: '/reports',
  [APPLICATIONS.SUPPORT]: '/support',
  [APPLICATIONS.MT5_PLATFORM_LINKS]: '/mt5-platform-links',
  [APPLICATIONS.CREDIT]: '/credit',
  [APPLICATIONS.PARTNERSHIP]: '/partnership',
  [APPLICATIONS.MY_PROFILE]: `/my-profile`,
  [APPLICATIONS.WITHDRAWAL_MT5_HISTORY]: '/withdrawal/mt5',
  [APPLICATIONS.ADD_WITHDRAWAL_WALLET]: '/withdrawal/wallet/request-withdrawal',
  [APPLICATIONS.WITHDRAWAL_WALLET_HISTORY]: '/withdrawal/wallet',
  [APPLICATIONS.ADD_WITHDRAWAL_MT5]: '/withdrawal/mt5/request-withdrawal',

  [APPLICATIONS.WALLET_DETAIL_HISTORY]: '/wallet-details',
  // GX_INJECT_APPLICATION_URLS
};

const NON_RESTRICTED_APPLICATIONS: TApplications[] = ['LANDING_PAGE'];

const getApplicationNameFromUrl = (url: string) =>
  Object.keys(APPLICATION_URLS).find((key: keyof typeof APPLICATION_URLS) =>
    matchPath(APPLICATION_URLS[key], url),
  );

export default APPLICATION_URLS;
export {
  APPLICATION_URLS,
  APPLICATIONS,
  getApplicationNameFromUrl,
  NON_RESTRICTED_APPLICATIONS,
  TApplications,
};
