import { useMemo } from 'react';
import ApiServices from '../../api';
import { MUTATION_KEYS } from './constants';
import { MutationDetails } from './types';

const { CHANGE_PASSWORD } = MUTATION_KEYS;

const useMutationFactory = <TData, TError = Error>() => {
  const mutationDetails = useMemo<MutationDetails<TData, TError>>(
    () => ({
      [CHANGE_PASSWORD]: () => ({
        mutationKey: [CHANGE_PASSWORD],
        mutationFn: (payload: any) => ApiServices.changePassword(payload),
      }),
    }),
    [],
  );
  return mutationDetails;
};

export default useMutationFactory;
