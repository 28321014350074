import { patch } from 'v2s-ui-core/app/Integrations/ApiService';
import { API_URLS } from './api/config';

export const SubmitUserTypeService = async <T>(payload: any) => {
  try {
    const response = await patch<T>(API_URLS.SUBMIT_USER_TYPE(payload.userId), {
      user_type: payload.userTypeId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
