import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'v2s-ui-core/app/containers/ErrorBoundary';

import GlobalProviders from './GlobalProviders';

const EntryPoint = ({ children }: React.PropsWithChildren) => (
  <BrowserRouter>
    <GlobalProviders>
      <ErrorBoundary>{children}</ErrorBoundary>
    </GlobalProviders>
  </BrowserRouter>
);
export default EntryPoint;
