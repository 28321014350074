import { get, post } from 'v2s-ui-core/app/Integrations/ApiService';
import { API_URLS } from './config';

const ApiService = {
  getAccountTypes: async <T>() => {
    try {
      const response = await get<T>(API_URLS.ACCOUNT_TYPE);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserAccount: async <T>(key: number) => {
    try {
      const response = await get<T>(`${API_URLS.USER_ACCOUNT}/${key}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getDocTypesList: async <T>() => {
    try {
      const response = await get<T>(API_URLS.GET_DOC_TYPES_LIST);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getWalletBalance: async <T>() => {
    try {
      const response = await get<T>(API_URLS.GET_WALLET_BALANCE);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUserAllAccounts: async <T>() => {
    try {
      const response = await get<T>(API_URLS.USER_ACCOUNT);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  changePassword: async <T>(payload: any) => {
    try {
      const response = await post<T>(API_URLS.CHANGE_PASSWORD, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserTypeDetail: async <T>() => {
    try {
      const response = await get<T>(API_URLS.GET_USER_TYPES);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
export default ApiService;
