// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./styles/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}

#app {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.scrollableDataTable {
  overflow-x: auto !important;
}
.MuiTableCell-root {
  text-align: center !important;
}
.MuiInputLabel-root {
  font-size: 14px !important;
}
@media (min-width: 370px) and (max-width: 600px) {
  .MuiInputLabel-root {
    font-size: 13px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');\n@import url('./styles/global.css');\n* {\n  font-family: 'Poppins', sans-serif;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  height: 100vh;\n  width: 100vw;\n}\n\n#root {\n  height: 100%;\n  width: 100%;\n}\n\n#app {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100vh;\n}\n.scrollableDataTable {\n  overflow-x: auto !important;\n}\n.MuiTableCell-root {\n  text-align: center !important;\n}\n.MuiInputLabel-root {\n  font-size: 14px !important;\n}\n@media (min-width: 370px) and (max-width: 600px) {\n  .MuiInputLabel-root {\n    font-size: 13px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
