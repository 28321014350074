import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import AsyncRenderer from 'v2s-ui-core/app/components/AsyncRenderer';

import { useEffect } from 'react';
import { MODES } from '~/config/constants';
import RequiresAuth from '../../components/Auth/RequiresAuth';
import { ProtectedLayout } from '../../components/Layout';
import APPLICATION_URLS from '../config/appsConfig';
import { PROTECTED_ROUTES } from '../config/routesConfig';
import PageNotFound from './PageNotFound';

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const isReferralRegistration =
      sessionStorage.getItem('isReferralRegistration') === 'true';
    if (isReferralRegistration) {
      navigate(`${APPLICATION_URLS.MY_PROFILE}?${MODES.IS_FIRST_TIME}=true`);
      sessionStorage.setItem('isReferralRegistration', 'false');
    }
  }, []);
  return (
    <RequiresAuth>
      <ProtectedLayout>
        <AsyncRenderer fallback={<span>Loading...</span>}>
          <Routes>
            {PROTECTED_ROUTES.map((_route) => (
              <Route
                path={_route.path}
                element={_route.element}
                key={_route.path}
              />
            ))}
            <Route
              path={APPLICATION_URLS.LOGIN}
              element={<Navigate to={APPLICATION_URLS.DASHBOARD} />}
            />
            <Route
              path={APPLICATION_URLS.LANDING_PAGE}
              element={<Navigate to={APPLICATION_URLS.DASHBOARD} />}
            />

            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </AsyncRenderer>
      </ProtectedLayout>
    </RequiresAuth>
  );
};

export default ProtectedRoutes;
