import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  pageNotFoundSubHeader: {
    id: 'pageNotFound.pageNotFoundSubHeader',
    defaultMessage: "Oops! The page you're looking for is under development.",
  },
  goToUserProfile: {
    id: 'pageNotFound.goToUserProfile',
    defaultMessage: 'Go to User Profile',
  },
});
