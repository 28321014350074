import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import { onLogout } from '~/shell/components/Auth/config/utils';
import { useQueryClient } from 'v2s-ui-core/app/Integrations/react-query';
import { getUserNameInitials } from '~/utils';

const USER_MENU_POPOVER = 'user-menu-popover';

export const useUserMenu = () => {
  const { setAuth, auth } = useUserAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const QueryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? USER_MENU_POPOVER : undefined;

  const onLogoutUser = () => {
    onLogout(setAuth, navigate, QueryClient);
  };

  return {
    open,
    id,
    anchorEl,
    handleClick,
    handleClose,
    onLogoutUser,
    email: auth?.email || '',
    userInitials: getUserNameInitials(auth?.full_name || ''),
  };
};
