import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  dashboard: {
    id: 'sideMenu.dashboard',
    defaultMessage: 'Dashboard',
  },
  bankAccounts: {
    id: 'sideMenu.bankAccounts',
    defaultMessage: 'Bank Accounts',
  },
  myKyc: {
    id: 'sideMenu.myKyc',
    defaultMessage: 'My KYC',
  },
  tradingAccounts: {
    id: 'sideMenu.tradingAccounts',
    defaultMessage: 'Trading Accounts',
  },

  wallets: {
    id: 'sideMenu.wallets',
    defaultMessage: 'Wallet',
  },
  deposits: {
    id: 'sideMenu.deposits',
    defaultMessage: 'Deposits',
  },
  'deposits.wallet': {
    id: 'sideMenu.deposits.wallet',
    defaultMessage: 'Wallet',
  },
  'deposits.MT5': {
    id: 'sideMenu.deposits.MT5',
    defaultMessage: 'MT5',
  },
  transfers: {
    id: 'sideMenu.transfers',
    defaultMessage: 'Transfers',
  },
  withdrawal: {
    id: 'sideMenu.withdrawal',
    defaultMessage: 'Withdrawal',
  },
  credit: {
    id: 'sideMenu.credit',
    defaultMessage: 'Credit',
  },
  support: {
    id: 'sideMenu.support',
    defaultMessage: 'Support',
  },
  'mt5.platform.links': {
    id: 'sideMenu.mt5.platform.links',
    defaultMessage: 'MT5 Platform Links',
  },
  reports: {
    id: 'sideMenu.reports',
    defaultMessage: 'Reports',
  },
  partnership: {
    id: 'sideMenu.partnership',
    defaultMessage: 'Partnership',
  },
  'partnership.myNetwork': {
    id: 'sideMenu.partnership.myNetwork',
    defaultMessage: 'My Network',
  },
  'partnership.genealogy': {
    id: 'sideMenu.partnership.genealogy',
    defaultMessage: 'My Network Genealogy',
  },
  'partnership.performance': {
    id: 'sideMenu.partnership.performance',
    defaultMessage: 'My Network Performance',
  },
  'partnership.statement': {
    id: 'sideMenu.partnership.statement',
    defaultMessage: 'My Statement',
  },
  'partnership.referral': {
    id: 'sideMenu.partnership.referral',
    defaultMessage: 'My Referral',
  },
});
