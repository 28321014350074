import * as yup from 'yup';
import { INPUT_FIELD_REGEX, INPUT_FIELDS_LENGTHS } from '../config/constants';
import messages from './validationMessages';

export const getFirstNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.FIRST_NAME.MAX,
      formatMessage(messages.firstNameTooLong),
    )
    .required(formatMessage(messages.firstNameRequired));

export const getLastNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.LAST_NAME.MAX,
      formatMessage(messages.lastNameTooLong),
    )
    .required(formatMessage(messages.lastNameRequired));

export const getEmailSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.EMAIL.MAX,
      formatMessage(messages.lastNameTooLong),
    )
    .email(formatMessage(messages.invalidEmail))
    .required(formatMessage(messages.emailRequired))
    .matches(INPUT_FIELD_REGEX.EMAIL, formatMessage(messages.invalidEmail));

export const getCountrySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.noCountrySelected));

export const getPhoneNumberSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.PHONE_NUMBER.MAX,
      formatMessage(messages.phoneNumberTooLong),
    )
    .matches(
      INPUT_FIELD_REGEX.PHONE_NUMBER,
      formatMessage(messages.invalidPhoneNumber),
    )
    .required(formatMessage(messages.phoneNumberRequired));

export const getRefferalSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.REFERRAL.MAX,
      formatMessage(messages.referralTooLong),
    )
    .required(formatMessage(messages.referralRequired));
export const getMetaCommentSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(INPUT_FIELDS_LENGTHS.REFERRAL.MAX, formatMessage(messages.metaTooLong))
    .required(formatMessage(messages.metaCommentRequired));

export const getPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.invalidPassword),
    )
    .required(formatMessage(messages.passwordRequired));

export const getOldPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.invalidOldPassword),
    )
    .required(formatMessage(messages.oldPasswordRequired));

export const getIsTncAggreedSchema = (formatMessage: (msg: any) => string) =>
  yup.boolean().oneOf([true], formatMessage(messages.tncNotAgreed));

export const getDropdownRequiredSchema = (
  formatMessage: (msg: any) => string,
) => yup.object().required(formatMessage(messages.fieldRequired));
export const getLocalCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.localCurrencyRequired));

export const getCryptoCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.cryptoCurrencyRequired));
export const getMT5AccountSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.mt5AccountRequired));
export const getPaymentMethodSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.paymentMethodRequired));
export const getLocalAmountSchema = (formatMessage: (msg: any) => string) =>
  yup
    .number()
    .required(formatMessage(messages.localAmountRequired))
    .min(1, formatMessage(messages.localAmountMin));
export const getBanAccountSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.bankAccountRequired));
export const getTradingCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.tradingCurrencyRequired));

export const getTradingAmountSchema = (formatMessage: (msg: any) => string) =>
  yup.number().required(formatMessage(messages.tradingAmountRequired));
export const getNoteSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(INPUT_FIELDS_LENGTHS.REMARK.MAX, formatMessage(messages.noteTooLong))
    .required(formatMessage(messages.noteRequired));

export const getCryptoAccAddressSchema = (
  formatMessage: (msg: any) => string,
) => yup.string().required(formatMessage(messages.cryptoAddRequired));

export const getPreferredLanguageSchema = (
  formatMessage: (msg: any) => string,
) => yup.object().required(formatMessage(messages.preferredLanguageRequired));

export const getAddressLine1Schema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.ADDRESS.MAX,
      formatMessage(messages.addressTooLong),
    )
    .required(formatMessage(messages.addressLine1Required));

export const getAddressLine2Schema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.ADDRESS.MAX,
      formatMessage(messages.addressTooLong),
    )
    .required(formatMessage(messages.addressLine2Required));

export const getCityTownSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.cityTownRequired));

export const getStateProvinceSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.stateProvinceRequired));

export const getZipPostalCodeSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.zipPostalCodeRequired));

export const getDateOfBirthSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.dateOfBirthRequired));

export const getGenderSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.genderRequired));

export const getNationalitySchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.nationalityRequired));

export const getFullNameSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.fullNameRequired));

export const getLeverageSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.leverageRequired));

export const getToAccountSchema = (formatMessage: (msg: any) => string) =>
  yup
    .mixed()
    .test(
      'is-object-or-string',
      formatMessage(messages.toAccountRequired),
      (value) =>
        (typeof value === 'object' || typeof value === 'number') &&
        value !== null,
    )
    .required(formatMessage(messages.toAccountRequired));

export const getFromAccountSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.fromAccountRequired));
export const getCompDateOfBirthSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.compDateOfBirthRequired));

export const getCompanyNameSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.compNameRequired));

export const getSubjectSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.subjectRequired));

export const getMessageSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.messageRequired));
export const getCompanyLicenseSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.companyLicenseRequired));
export const getTransferTypeSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.transferTypeRequired));
