import ComposeProviders from 'v2s-ui-core/app/containers/ComposeProviders';
import IntlProvider from 'v2s-ui-core/app/Integrations/Localization';
import ReactQueryClientProvider from 'v2s-ui-core/app/Integrations/react-query/ReactQueryClientProvider';

import ToastNotificationsProvider from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import SessionStorageProvider from '~/shell/context/sessionStorage/context';
import GlobalThemeProvider from '~/shell/theme/GlobalThemeProvider';
import AuthProvider from '../Auth/AuthProvider';

const GLOBAL_PROVIDERS = [
  SessionStorageProvider,
  GlobalThemeProvider,
  ReactQueryClientProvider,
  IntlProvider,
  ToastNotificationsProvider,
  AuthProvider,
];

const GlobalProviders = ({ children }: React.PropsWithChildren) => (
  <ComposeProviders providers={GLOBAL_PROVIDERS}>{children}</ComposeProviders>
);

export default GlobalProviders;
