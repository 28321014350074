// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 370px) and (max-width: 768px) {
  .headerTitle,
  h6 {
    font-size: 14px !important;
    line-height: 8px;
  }
}
@media (min-width: 370px) and (max-width: 768px) {
  .buttonTitle,
  p {
    font-size: 12px !important;
  }
  .buttonTitle,
  h6 {
    font-size: 18px !important;
  }
}
@media (min-width: 370px) and (max-width: 768px) {
  .userProfileCard {
    display: none !important;
  }
}
@media (min-width: 370px) and (max-width: 600px) {
  .hideContentForMobile {
    display: none !important;
  }
}
@media (min-width: 370px) and (max-width: 768px) {
  .tableHeaderTitle,
  h6,
  h5 {
    font-size: 16px !important;
  }
  .signup-form-container h4 {
    font-size: 20px;
  }
}

@media (min-width: 370px) and (max-width: 768px) {
  .liveAddButtons {
    height: 35px !important;
    width: 170px !important;
    font-size: 11px !important;
    line-height: normal !important;
  }
  .changePasswordButtons {
    height: 35px !important;
    width: 154px !important;
    font-size: 10px !important;
    line-height: normal !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE;;IAEE,0BAA0B;IAC1B,gBAAgB;EAClB;AACF;AACA;EACE;;IAEE,0BAA0B;EAC5B;EACA;;IAEE,0BAA0B;EAC5B;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;;;IAGE,0BAA0B;EAC5B;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,uBAAuB;IACvB,0BAA0B;IAC1B,8BAA8B;EAChC;EACA;IACE,uBAAuB;IACvB,uBAAuB;IACvB,0BAA0B;IAC1B,8BAA8B;EAChC;AACF","sourcesContent":["@media (min-width: 370px) and (max-width: 768px) {\n  .headerTitle,\n  h6 {\n    font-size: 14px !important;\n    line-height: 8px;\n  }\n}\n@media (min-width: 370px) and (max-width: 768px) {\n  .buttonTitle,\n  p {\n    font-size: 12px !important;\n  }\n  .buttonTitle,\n  h6 {\n    font-size: 18px !important;\n  }\n}\n@media (min-width: 370px) and (max-width: 768px) {\n  .userProfileCard {\n    display: none !important;\n  }\n}\n@media (min-width: 370px) and (max-width: 600px) {\n  .hideContentForMobile {\n    display: none !important;\n  }\n}\n@media (min-width: 370px) and (max-width: 768px) {\n  .tableHeaderTitle,\n  h6,\n  h5 {\n    font-size: 16px !important;\n  }\n  .signup-form-container h4 {\n    font-size: 20px;\n  }\n}\n\n@media (min-width: 370px) and (max-width: 768px) {\n  .liveAddButtons {\n    height: 35px !important;\n    width: 170px !important;\n    font-size: 11px !important;\n    line-height: normal !important;\n  }\n  .changePasswordButtons {\n    height: 35px !important;\n    width: 154px !important;\n    font-size: 10px !important;\n    line-height: normal !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
