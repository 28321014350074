import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  selectUserType: {
    id: 'selectUserType',
    defaultMessage: 'Select User Type',
  },
  submit: {
    id: 'submit',
    defaultMessage: 'Submit',
  },
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Confirm',
  },
  selectPaymentMethod: {
    id: 'selectPaymentMethod',
    defaultMessage: 'Select Payment Method',
  },
});
