import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Box from 'v2s-ui-core/app/components/Box';
import Logo from '~/assets/images/logo.svg';
import { StyledAppBar } from './styles';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { isMobile } from 'v2s-ui-core/app/utils';
import UserMenu from './components/UserMenu';

const Appbar = ({
  open,
  onSidebarToggle,
}: {
  open: boolean;
  onSidebarToggle: (val: boolean) => void;
}) => (
  <StyledAppBar position='fixed' color='default'>
    <Toolbar className='app-header-toolbar'>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='menu'
        sx={[{ mr: 2 }, open && { display: 'no' }]}
        onClick={() => {
          onSidebarToggle(!open);
        }}
      >
        {open ? <KeyboardBackspaceIcon /> : <MenuIcon />}
      </IconButton>
      {isMobile() ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <img className='appbar-logo' src={Logo} alt='company-logo' />
        </Box>
      ) : (
        <img className='appbar-logo' src={Logo} alt='company-logo' />
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: 'flex' }}>
        <IconButton
          edge='end'
          size='large'
          aria-label='show 17 new notifications'
          color='inherit'
        >
          <NotificationsNoneOutlinedIcon />
        </IconButton>
        <UserMenu />
      </Box>
    </Toolbar>
  </StyledAppBar>
);

export default Appbar;
