import { useUserAuth } from '../components/Auth/AuthProvider';

import DefaultRoutes from './components/DefaultRoutes';
import ProtectedRoutes from './components/ProtectedRoutes';

const RoutingProvider = () => {
  const { isAuthenticated } = useUserAuth();

  return !isAuthenticated ? (
    <>
      <DefaultRoutes />
    </>
  ) : (
    <ProtectedRoutes />
  );
};

export default RoutingProvider;
