import BankAccounts from './images/Bank Accounts.svg';
import { default as InternalTransfersIcon } from './images/ChangeLeverage.svg';
import ClientsInOutIcon from './images/ClientsInOut.svg';
import CreditIcon from './images/Credit.svg';
import dashBoardIcon from './images/Dashboard.svg';
import DepositsIcon from './images/Deposits.svg';
import IBManagementIcon from './images/IBManagement.svg';
import MT5PlatformLinksIcon from './images/MT5PlatformLinks.svg';
import MT5Icon from './images/Mt5.svg';
import MyKYCIcon from './images/MyKYC.svg';
import PartnershipIcon from './images/Partnership.svg';
import ReportsIcon from './images/Reports.svg';
import RequestsIcon from './images/Requests.svg';
import SupportIcon from './images/Support.svg';
import TradingAccountsIcon from './images/TradingAccounts.svg';
import WalletsIcon from './images/Wallets.svg';
import WithdrawalIcon from './images/Withdrawal.svg';
import AxisBankIcon from './images/axisBank.svg';
import ChangeLeverage from './images/change-leverage.svg';
import CryptoMethodIcon from './images/crypto.svg';
import ExpandIcon from './images/expandIcon.svg';
import GenealogyIcon from './images/genealogy.svg';
import NetworkIcon from './images/network.svg';

import TraderIcon from './images/TraderIcon.svg';
import CopyImage from './images/copy.svg';
import LocalTransferIcon from './images/localTransfer.svg';
import MetaTraderLogo from './images/metaPlatformLogo.png';
import ChangePassword from './images/paswword.svg';
import PeskaMethodIcon from './images/peska.svg';
import MetaTraderFiveImage from './images/platformLink1.png';
import MetaTraderWebImage from './images/platformLink2.png';
import MetaTraderAndroidImage from './images/platformLink3.png';
import MetaTraderAndroidIos from './images/platformLink4.png';

import ReferralIcon from './images/refferal.svg';
import StatementIcon from './images/statement.svg';
import successIcon from './images/successIcon.svg';
import WirePaymentIcon from './images/wire-transfer-1 1.svg';

export {
  AxisBankIcon,
  BankAccounts,
  ChangeLeverage,
  ChangePassword,
  ClientsInOutIcon,
  CopyImage,
  CreditIcon,
  CryptoMethodIcon,
  dashBoardIcon,
  DepositsIcon,
  ExpandIcon,
  GenealogyIcon,
  IBManagementIcon,
  InternalTransfersIcon,
  LocalTransferIcon,
  MetaTraderAndroidImage,
  MetaTraderAndroidIos,
  MetaTraderFiveImage,
  MetaTraderLogo,
  MetaTraderWebImage,
  MT5Icon,
  MT5PlatformLinksIcon,
  MyKYCIcon,
  NetworkIcon,
  PartnershipIcon,
  PeskaMethodIcon,
  ReferralIcon,
  ReportsIcon,
  RequestsIcon,
  StatementIcon,
  successIcon,
  SupportIcon,
  TraderIcon,
  TradingAccountsIcon,
  WalletsIcon,
  WirePaymentIcon,
  WithdrawalIcon,
};
