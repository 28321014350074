import { TDocumentsTypes, TFilteredBankFile } from '~/config/types';
import {
  BANK_STATEMENT_DOCUMENT_DETAIL,
  MODES,
  TRANSACTION_TYPES_KEYS,
} from '../config/constants';

export const truncateString = (value: string | number): string => {
  const stringValue = String(value);
  return stringValue.length > 15
    ? `${stringValue.substring(0, 12)}...`
    : stringValue;
};

// returning bank statement document id and name as suggested
export const filterBankFile = (data: TDocumentsTypes): TFilteredBankFile => {
  const bankDocuments = data.Bank || [];

  const bankStatement = bankDocuments.find(
    (document) => document.document_name === 'Bank Statement',
  );

  const frontSide = bankStatement?.sides.find(
    (side) => side.document_side === 'Front',
  );

  return frontSide
    ? { document_name: bankStatement.document_name, id: frontSide.id }
    : BANK_STATEMENT_DOCUMENT_DETAIL; // default value if not found
};

type FilterAccountType = (data: any, key: string) => { [key: string]: any };
export const filterAccountType: FilterAccountType = (data, key) => {
  const fileterdData =
    key === TRANSACTION_TYPES_KEYS.MT5
      ? data?.find((doc: any) => doc?.name === 'Trading')
      : data?.find((doc: any) => doc?.name === 'Wallet');

  return fileterdData;
};
export const getFormActionButtonText = (mode: string): string =>
  ({
    [MODES.ADD]: 'Submit',
    [MODES.EDIT]: 'Save',
  }[mode] || 'Close');

export const getDialogTitle = (mode: string): string =>
  ({
    [MODES.VIEW]: 'View',
    [MODES.EDIT]: 'Edit',
    [MODES.ADD]: 'Add',
  }[mode] || '');

export const getUserNameInitials = (username: string) => {
  if (!username.trim()) {
    return ''; // Handle empty or invalid usernames
  }

  const names = username.trim().split(/\s+/).filter(Boolean);

  if (names.length === 1) {
    return names[0][0].toUpperCase(); // Handle single-word username
  }

  return (names[0][0] + names.pop()?.[0]).toUpperCase();
};

export const fileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
export function findIndexByDocType(dataArray: Array<any>, type: string): any {
  const index = dataArray.findIndex((item) => item.document_side === type);
  const data = dataArray[index];
  const formRequiredValues = {
    name: data?.latest_version?.filename,
    id: data?.id,
    status: data?.latest_version?.status?.status_name,
    file_location: data?.latest_version?.original_doc_location,
    remark: data?.latest_version?.remark,
  };
  return formRequiredValues;
}
export const getMaxDateAsCurrentDate = (date: Date) => {
  const maxDateAsCurrentDate = date;
  maxDateAsCurrentDate.setFullYear(maxDateAsCurrentDate.getFullYear());
  return maxDateAsCurrentDate;
};

export const filterAccountTypeData = (data: any[], type: string) =>
  data?.filter((account) => account?.account_type?.type === type);

export const renderErrorMessage = (error: any) => {
  if (typeof error === 'string') {
    return error;
  } else {
    return Object.values(error).join(', ');
  }
};
