import {
  BankAccounts,
  CreditIcon,
  dashBoardIcon,
  DepositsIcon,
  InternalTransfersIcon,
  MT5Icon,
  MT5PlatformLinksIcon,
  MyKYCIcon,
  PartnershipIcon,
  ReportsIcon,
  SupportIcon,
  TradingAccountsIcon,
  WalletsIcon,
  WithdrawalIcon,
} from '../../../../../assets/index';

import APPLICATION_URLS from '../../../../Routing/config/appsConfig';

import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from './messages';
import { SIDEBAR_ITEM } from './types';

export const DRAWER_WIDTH = 240;

export const SIDEBAR_MENUS: SIDEBAR_ITEM[] = [
  {
    menuName: <FormattedMessage {...messages.dashboard} />,
    altText: 'Dashboard',
    icon: dashBoardIcon,
    id: 'dashboard',
    path: APPLICATION_URLS.DASHBOARD,
  },
  {
    menuName: <FormattedMessage {...messages.bankAccounts} />,
    altText: 'Bank Accounts',
    icon: BankAccounts,
    id: 'bankAccounts',
    path: APPLICATION_URLS.BANK_ACCOUNTS,
  },
  {
    menuName: <FormattedMessage {...messages.myKyc} />,
    altText: 'My KYC',
    icon: MyKYCIcon,
    id: 'myKyc',
    path: APPLICATION_URLS.MY_KYC,
  },
  {
    menuName: <FormattedMessage {...messages.tradingAccounts} />,
    altText: 'Trading Accounts',
    icon: TradingAccountsIcon,
    id: 'tradingAccounts',
    path: APPLICATION_URLS.TRADING_ACCOUNTS_LIST,
  },
  {
    menuName: <FormattedMessage {...messages.wallets} />,
    altText: 'Wallet',
    icon: WalletsIcon,
    id: 'wallets',
    path: APPLICATION_URLS.WALLET_DETAIL_HISTORY,
  },
  {
    menuName: <FormattedMessage {...messages.deposits} />,
    altText: 'Deposits',
    icon: DepositsIcon,
    id: 'deposits',
    path: APPLICATION_URLS.DEPOSITS,
    submenus: [
      {
        menuName: <FormattedMessage {...messages['deposits.wallet']} />,
        altText: 'Wallet',
        id: 'wallet',
        path: APPLICATION_URLS.DEPOSITS_WALLET,
        icon: WalletsIcon,
      },
      {
        menuName: <FormattedMessage {...messages['deposits.MT5']} />,
        altText: 'MT5',
        id: 'MT5',
        path: APPLICATION_URLS.DEPOSITS_MT5,
        icon: MT5Icon,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.transfers} />,
    altText: 'Transfers',
    icon: InternalTransfersIcon,
    id: 'transfers',
    path: APPLICATION_URLS.TRANSFERS,
  },
  {
    menuName: <FormattedMessage {...messages.withdrawal} />,
    altText: 'Withdrawal',
    icon: WithdrawalIcon,
    id: 'withdrawal',
    path: APPLICATION_URLS.WITHDRAWAL,
    submenus: [
      {
        menuName: <FormattedMessage {...messages['deposits.wallet']} />,
        altText: 'Wallet',
        id: 'wallet',
        path: APPLICATION_URLS.WITHDRAWAL_WALLET_HISTORY,
        icon: DepositsIcon,
      },
      {
        menuName: <FormattedMessage {...messages['deposits.MT5']} />,
        altText: 'MT5',
        id: 'MT5',
        path: APPLICATION_URLS.WITHDRAWAL_MT5_HISTORY,
        icon: DepositsIcon,
      },
    ],
  },

  {
    menuName: <FormattedMessage {...messages.credit} />,
    altText: 'Credit',
    icon: CreditIcon,
    id: 'credit',
    path: APPLICATION_URLS.CREDIT,
  },
  {
    menuName: <FormattedMessage {...messages.support} />,
    altText: 'Support',
    icon: SupportIcon,
    id: 'support',
    path: APPLICATION_URLS.SUPPORT,
  },
  {
    menuName: <FormattedMessage {...messages['mt5.platform.links']} />,
    altText: 'MT5 Platform Links',
    icon: MT5PlatformLinksIcon,
    id: 'mt5PlatformLinks',
    path: APPLICATION_URLS.MT5_PLATFORM_LINKS,
  },
  {
    menuName: <FormattedMessage {...messages.reports} />,
    altText: 'Reports',
    icon: ReportsIcon,
    id: 'reports',
    path: APPLICATION_URLS.REPORTS,
  },
  {
    menuName: <FormattedMessage {...messages.partnership} />,
    altText: 'Partnership',
    icon: PartnershipIcon,
    id: 'partnership',
    path: APPLICATION_URLS.PARTNERSHIP,
  },
];
