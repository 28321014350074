import type { RouteProps } from 'react-router-dom';

import { lazy } from 'react';

import APPLICATION_URLS from './appsConfig';

const BankAccount = lazy(() => import('~/modules/bankAccounts'));

const MT5AccountWithdrawalForm = lazy(
  () => import('../../../modules/withdrawalForm/container/MT5Account'),
);

const WalletWithdrawalForm = lazy(
  () => import('../../../modules/withdrawalForm/container/Wallet'),
);
const MT5Withdrawal = lazy(
  () => import('../../../modules/WithdrawalHistory/container/MT5Withdrawal'),
);

const WalletWithdrawal = lazy(
  () => import('../../../modules/WithdrawalHistory/container/WalletWithdrawal'),
);
const MyProfile = lazy(() => import('../../../modules/MyProfile'));

const AuthApp = lazy(() => import('~/modules/Auth'));
const MT5Account = lazy(
  () => import('../../../modules/depositForm/container/MT5Account'),
);
const Wallet = lazy(
  () => import('../../../modules/depositForm/container/Wallet'),
);
const WalletHistory = lazy(
  () => import('../../../modules/depositHistory/container/WalletHistory'),
);
const MT5History = lazy(
  () => import('../../../modules/depositHistory/container/MT5History'),
);
const WalletDetailHistory = lazy(
  () => import('../../../modules/WalletDetailHistory'),
);

const TradingAccounts = lazy(() => import('../../../modules/TradingAccounts'));
const MyKyc = lazy(() => import('../../../modules/MyKyc'));

// GX_INJECT_MODULES_IMPORT

//-------------------------------DEFAULT ROUTES-------------------------------//

const DEFAULT_ROUTES: RouteProps[] = [];

//-------------------------------AUTH ROUTES-------------------------------//

const AUTH_ROUTES: RouteProps[] = [
  {
    path: APPLICATION_URLS.AUTH,
    element: <AuthApp />,
  },
  // GX_INJECT_AUTH_ROUTES
];

//-------------------------------PROTECTED ROUTES-------------------------------//

const PROTECTED_ROUTES: RouteProps[] = [
  {
    path: APPLICATION_URLS.MY_PROFILE,
    element: <MyProfile />,
  },
  {
    path: APPLICATION_URLS.BANK_ACCOUNTS,
    element: <BankAccount />,
  },

  {
    path: APPLICATION_URLS.DEPOSITS_MT5,
    element: <MT5History />,
  },
  {
    path: APPLICATION_URLS.DEPOSITS_WALLET,
    element: <WalletHistory />,
  },

  {
    path: APPLICATION_URLS.ADD_DEPOSITS_WALLET,
    element: <Wallet />,
  },
  {
    path: APPLICATION_URLS.ADD_DEPOSITS_MT5,
    element: <MT5Account />,
  },
  {
    path: APPLICATION_URLS.WITHDRAWAL_WALLET_HISTORY,
    element: <WalletWithdrawal />,
  },
  {
    path: APPLICATION_URLS.WITHDRAWAL_MT5_HISTORY,
    element: <MT5Withdrawal />,
  },
  {
    path: APPLICATION_URLS.ADD_WITHDRAWAL_WALLET,
    element: <WalletWithdrawalForm />,
  },
  {
    path: APPLICATION_URLS.ADD_WITHDRAWAL_MT5,
    element: <MT5AccountWithdrawalForm />,
  },
  {
    path: APPLICATION_URLS.WALLET_DETAIL_HISTORY,
    element: <WalletDetailHistory />,
  },
  {
    path: APPLICATION_URLS.TRADING_ACCOUNTS,
    element: <TradingAccounts />,
  },
  {
    path: APPLICATION_URLS.MY_KYC,
    element: <MyKyc />,
  },

  // GX_INJECT_PROTECTED_ROUTES
];

export { AUTH_ROUTES, DEFAULT_ROUTES, PROTECTED_ROUTES };
