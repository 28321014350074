import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../services/queries/useQueryFactory';

const useAccountTypeList = <TData, TError = Error>(values: {
  enabled: boolean;
  auth?: boolean;
}) => {
  const queryDetails = useQueryFactory<TData, TError>();

  const queryResult = useQuery<TData, TError>({
    ...queryDetails.accountType(),
    enabled: values.enabled,
  });

  return queryResult;
};

export default useAccountTypeList;
