import TextField from 'v2s-ui-core/app/components/Input/TextField';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { useCallback, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const TogglePasswordIcon = ({
  showPassword,
  onClick,
}: {
  showPassword: boolean;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
  <span onClick={onClick}>
    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
  </span>
);

const PasswordInputField: React.FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <TogglePasswordIcon
              showPassword={showPassword}
              onClick={handleShowPasswordClick}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordInputField;
