import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';

const useToast = () => {
  const { showToastNotification } = useShowToastNotifications();

  const triggerErrorToast = (error: TErrorResponse) => {
    if (error.data && error.data.errors && error.data.errors.length > 0) {
      showToastNotification(error.data.errors[0].detail, 'error');
    }
  };

  const triggerSuccessToast = (message: string) => {
    showToastNotification(message, 'success');
  };

  return { triggerErrorToast, triggerSuccessToast };
};

export default useToast;

export interface TErrorResponse {
  data: { type: string; errors: Error[] };
}

export interface Error {
  code: string;
  detail: string;
  attr: string;
}
