import { useMemo } from 'react';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import ApiService from '../../api';
import { QUERY_KEYS } from './constants';
import { QueryDetails } from './types';
import { TDocumentTypeListResponse } from '~/config/types';

const { ACCOUNT_TYPE, USER_ACCOUNT, GET_DOC_TYPES_LIST, GET_WALLET_BALANCE } =
  QUERY_KEYS;

export const useQueryFactory = <TData, TError = Error>() => {
  const { accountTypeList } = useUserAuth();
  const key = accountTypeList?.data?.filter(
    (item: any) => item.name === 'Wallet',
  )?.[0]?.id;

  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [ACCOUNT_TYPE]: () => ({
        queryKey: [ACCOUNT_TYPE],
        queryFn: () => ApiService.getAccountTypes<TData>(),
      }),
      [USER_ACCOUNT]: () => ({
        queryKey: [USER_ACCOUNT],
        queryFn: () => ApiService.getUserAccount<TData>(key),
      }),
      [GET_DOC_TYPES_LIST]: () => ({
        queryKey: [GET_DOC_TYPES_LIST],
        queryFn: () => ApiService.getDocTypesList<TDocumentTypeListResponse>(),
      }),
      [GET_WALLET_BALANCE]: () => ({
        queryKey: [GET_WALLET_BALANCE],
        queryFn: () => ApiService.getWalletBalance<TData>(),
      }),
    }),
    [],
  );

  return queryDetails;
};
