import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import messages from '../messages';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import APPLICATION_URLS from '../config/appsConfig';

const PageNotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container
      fixed
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
      }}
    >
      <Box>
        <Typography variant='h5' gutterBottom>
          <FormattedMessage {...messages.pageNotFoundSubHeader} />
        </Typography>
        <Button
          variant='contained'
          onClick={() => navigate(APPLICATION_URLS.MY_PROFILE)}
        >
          <FormattedMessage {...messages.goToUserProfile} />
        </Button>
      </Box>
    </Container>
  );
};

export default PageNotFound;
